import React, { useContext, useEffect, useState } from "react";
import InternSideBar from "../InternSidebar/InternSidebar"
import QCStyle from '../../QC/QCDashboard/QCDashboard.module.css'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dropdown from '../../../Assets/Images/Dropdown Icon.svg'
import QcProfile from '../../../Assets/Images/Profilephoto1.png'
import Style from '../InternDashboard/InternDashboard.module.css'
import { GetInternCAR, GetJudgement, GetTotalAssingJudegment } from "../../BaseApi/Baseapi";
import { UseContext } from "../../Context/Context";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'white',

    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 90,
}));
function InternDashboard() {
const {isAuthorized,setIsAuthorized}=useContext(UseContext);
    const [qcName, setQcName] = useState('');
    const [qcRoll, setQcRoll] = useState('');
    const [judgement, setJudgement] = useState([]);
    const [date, setDate] = useState();
    const [totaljudegement,setTotaljudegment]=useState(0);
    const[filecompleted,setFilecompleted]=useState(0);
    const[fileapproved,setFileapproved]=useState(0);
    const[filerejected,setFileReject]=useState(0)
    const [internwokr,setInternwork]=useState([]);
    const [buttonstyle, setButtonstyle] = useState(1);
    useEffect(() => {
        setQcName(sessionStorage.getItem('QcName'));
        setQcRoll(sessionStorage.getItem('QcRoll'));
    }, [])

    const GetJudgment = async () => {
        try {
            const Id = sessionStorage.getItem('InterId');

            const Data = await fetch(`${GetJudgement}${Id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!Data.ok) {
                throw new Error(`Error ${Data.status}: ${Data.statusText}`);
            }

           
            const Responce = await Data.json();
            setJudgement(Responce);
           
            console.log(Responce);

           

        } catch (error) {
            console.error("Error:", error); 
            // alert(`Request failed: ${error.message}`);
        }
    };
    // const gettotaljudegment=()=>{
    //     const valume=judgement.map((data)=>data.noOfJudgement)
    //     setTotaljudegment(valume);
    // }

    useEffect(() => {
        GetJudgment();
      
    }, []);

    // useEffect(()=>{
    //     gettotaljudegment();
    // },[judgement])


    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    
    console.log(isAuthorized);

    const Getalljudgment=async()=>{
        try{
            const ID=sessionStorage.getItem('InterId')
            const Data=await fetch(`${GetTotalAssingJudegment}${ID}`,{
                method:"GET",
                headers:{
                    'content-type':'application/json'
                }
            })
            const Responce=await Data.json();
            setTotaljudegment(Responce)
        }catch(error){

        }
    };

    const GetallCAR=async()=>{
        try{
            const ID=sessionStorage.getItem('InterId')
            const Data=await fetch(`${GetInternCAR}${ID}`,{
                method:"GET",
                headers:{
                    'content-type':'application/json'
                }
            })
            const Responce=await Data.json();
            setFileapproved(Responce.approvedCount);
            setFilecompleted(Responce.completedCount);
            setFileReject(Responce.rejectedCount);
            
        
        }catch(error){

        }
    };


    useEffect(()=>{
        Getalljudgment();
        GetallCAR();
    },[]);

    
    


    return (
        <>
            <div className={QCStyle.MainDiv}>
                <div className={QCStyle.SideBar}>
                    <InternSideBar buttonstyle={buttonstyle}/>

                </div>
                <div style={{width:"80vw",position:"relative"}}>
                <div  style={{padding:'15px',backgroundColor:"#f5f5f5"}}>
                    <div className={QCStyle.Div1}>
                        <Box sx={{ flexGrow: 1, }} >
                            {/* <div  style={{gap:'10px',position:'relative',display:'flex',alignItems:'center',marginBottom:'15px'}}>
                                <div className={QCStyle.Div3} style={{ width: '25%' }}>
                                    <span>This month: 1 Jun 2024 - 12 Jun 2024</span>
                                    <img src={Dropdown} height={'24px'}></img>
                                </div>
                                <div className={QCStyle.Div3} style={{ width: '25%' }} >
                                    <span
                                        style={{
                                            Fontsize: '12px',
                                            Fontweight: '600',
                                            FOntFamily: 'Poppins',
                                            color: '#424242',
                                        }}>TEAM</span>:
                                    <span
                                        style={{
                                            Fontsize: '12px',
                                            FOntFamily: 'Poppins',
                                            color: '#424242',
                                        }}
                                    >{qcName} ({qcRoll})</span>
                                </div>
                            </div> */}
                            <Grid container spacing={2} >
                                <Grid item xs={3}>
                                    <Item >
                                        <div className={QCStyle.BoxValue}>{totaljudegement}</div>
                                        <div className={QCStyle.BoxLabel}>Assigned Judgements</div>

                                    </Item>

                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div className={QCStyle.BoxValue}>{filecompleted}</div>
                                        <div className={QCStyle.BoxLabel}>Qc Pending</div>

                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div style={{ color: "#008000" }} className={QCStyle.BoxValue}>{fileapproved}</div>
                                        <div className={QCStyle.BoxLabel}>QC Approved</div>

                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div className={QCStyle.BoxValue}>{filerejected}</div>
                                        <div className={QCStyle.BoxLabel}>QC Rejected</div>

                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>



                    <div style={{ margin: "15px" }}>
                        <table class="table table-bordered bg-white  table-hover"  >
                            <thead style={{position:'sticky',top:'0',backgroundColor:'white',zIndex:'1000'}}>
                                <tr className={Style.TableHeading}>
                                    <th >Sr.no</th>
                                    <th >Task Assigned on</th>
                                    <th >Assigned Year</th>
                                    <th >Assigned Volume</th>
                                    <th >Part</th>
                                    <th >No. of Judgement</th>
                                    <th class='text-warning'>Qc Pending</th>
                                    <th  >QC Approved</th>
                                    <th class='text-danger'>Qc Rejected</th>
                                    <th class='text-success'>File Submitted</th>


                                </tr>
                            </thead>
                            <tbody>
                                {judgement.sort((a,b)=> new Date(b.assiginingDate) - new Date(a.assiginingDate)).map((item, index) => (
                                    <tr className={Style.TableRow} key={index}>
                                        <td>{index + 1}</td>
                                        <td>{formatDate(item.assiginingDate)}</td>
                                        <td>{item.assignedYear}</td>
                                        <td>{item.assignedVolume}</td>
                                        <td>{item.part}</td>
                                        <td>{item.noOfJudgement}</td>
                                        <td>{item.fileReceived}</td>
                                        <td >{item.qcApproved}</td>
                                        <td>{item.qcRejected}</td>
                                        <td>{item.fileReceived+item.qcApproved+item.qcRejected}</td>

                                    </tr>
                                    
                                ))}
                                <tr style={{borderTop:'2px solid black'}}>
                                    <td >Total</td>
                                    <td colSpan={4}></td>
                                    <td>{totaljudegement}</td>
                                    <td>{filecompleted}</td>
                                    <td>{fileapproved}</td>
                                    <td>{filerejected}</td>
                                    <td>{filecompleted+fileapproved+filerejected}</td>


                                </tr>



                            </tbody>
                        </table>
                    </div>
                </div >
                </div>
            </div >
        </>
    )
};


export default InternDashboard