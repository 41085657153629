import React, { useContext, useState } from "react";

import SingupStyle from '../SingUp/Singup.module.css'
import SIngupImage from '../../Assets/Images/Singuplogo.png'
import LowTechlogo from '../../Assets/Images/LowTeclogo.png'
import Googlelogo from '../../Assets/Images/Googlelogo.svg'
import EyeIcon from '../../Assets/Images/googleye.svg'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { UseContext } from "../Context/Context";







function SingUp() {

    const {
        singupname, setSingName,
        singuplastName, setSingupLastName,
        singupEMail, setSingupEmail,
        singupPassword, setSingupPassword,
        singupconfirmPassword,
        setUserStatus,
        setAdminName,
        userid,setuserid,

    } = useContext(UseContext);

    const [showpssword, setShowpssword] = useState(false);
    const Navigate = useNavigate();


    const HandelSingAdmin = async (e) => {
        e.preventDefault();
        // alert('Admin SingUp',);


        //   const FormData=new FormData();
        //   FormData.append('userName',singupname)
        //   FormData.append('email',singupname)
        //   FormData.append('firstName',singupname)
        //   FormData.append('lastName',singupname)
        //   FormData.append('password',singupname)
        try {
            const bodyData = {
                userName: singupname,
                email: singupEMail,
                firstName: singupname,
                lastName: singuplastName,
                password: singupPassword,
                confirmPassword: singupconfirmPassword,
            }

            const Data = await fetch('http://localhost:8080/api/user/signUp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData),


            });
            var Responce = await Data.json();
            console.log('Responce', Responce, typeof (Responce.id));
            setUserStatus(Responce.roleName);
            setAdminName(Responce.userName);
            
             
            

            sessionStorage.setItem('userName', Responce.userName);
            sessionStorage.setItem('roleName', Responce.roleName);
            sessionStorage.setItem('AdminId', Responce.id);


            if (Responce.roleName === 'Admin') {
                Navigate('/Dashboard');
               
            }
            
        } catch (error) {
            // alert('Error')
            console.log('error', error);
        }
        if (Responce.errorCode == 409) {
            
        }

    }

    return (
        <>

            <div className={SingupStyle.Main} >
                <div className={SingupStyle.Singup}>

                    <img src={SIngupImage} className={SingupStyle.Image}></img>

                </div>
                <div className={SingupStyle.FormDivMain}  >
                    <div style={{ position: 'relative', top: '5%', bottom: '5%', width: '100%' }}>
                        <div >
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10%', marginRight: '10%' }}>
                                <div>
                                    <img src={LowTechlogo} alt='logo' width='70px'></img>
                                </div>
                                <div>
                                    <div className={SingupStyle.heading}>Welcome!!
                                    </div>

                                    <div className={SingupStyle.registerToLaw}>Register to Law Tech Platform</div>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div style={{ position: 'relative', marginLeft: '10%', marginRight: '10%' }}>
                                <button className={SingupStyle.googlebutton} >
                                    <img src={Googlelogo} alt="googlelogo" height={'20px'}></img>
                                    <div className={SingupStyle.googletext}>Google</div>

                                </button>

                            </div>



                        </div>

                        <div style={{ position: 'relative', }}>
                            <div style={{ margin: '10px' }}>
                                <form onSubmit={HandelSingAdmin}>
                                    <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                        <div class="form-group " style={{ width: '35%' }}>
                                            <label for="exampleFormControlInput1" className={SingupStyle.label}>First Name  <span style={{ color: 'red' }}>*</span></label>
                                            <div className={SingupStyle.input}>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="First Name" style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent' }} value={singupname} onChange={(e) => setSingName(e.target.value)}></input>
                                            </div>

                                        </div>
                                        <div class="form-group " style={{ width: '35%' }}>
                                            <label for="exampleFormControlInput1" className={SingupStyle.label}>Last Name <span style={{ color: 'red' }}>*</span></label>
                                            <div className={SingupStyle.input}>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Last Name" style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent' }} value={singuplastName} onChange={(e) => setSingupLastName(e.target.value)}></input>
                                            </div>

                                        </div>
                                    </div>
                                    <br />
                                    <div class="form-group" style={{ marginLeft: '10%', marginRight: '10%' }} >
                                        <label for="exampleFormControlInput1" className={SingupStyle.label}>Email <span style={{ color: 'red' }}>*</span></label>
                                        <div className={SingupStyle.input}>
                                            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent' }} value={singupEMail} onChange={(e) => setSingupEmail(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                        <div class="form-group " style={{ width: '35%' }}>
                                            <label for="exampleFormControlInput1" className={SingupStyle.label}>Password  <span style={{ color: 'red' }}>*</span></label>
                                            <div class="form-control d-flex align-items-center" className={SingupStyle.input}>
                                                <input type={showpssword == true ? 'text' : "password"} class="form-control" id="exampleFormControlInput1" placeholder="Password " style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent' }} value={singupPassword} onChange={(e) => setSingupPassword(e.target.value)}></input>
                                                <img alt="icon" height={'24px'} src={EyeIcon} style={{ cursor: 'pointer' }} onClick={() => setShowpssword(!showpssword)}></img>

                                            </div>


                                        </div>
                                        <div class="form-group " style={{ width: '35%' }}>
                                            <label for="exampleFormControlInput1" className={SingupStyle.label}>Confirm Password <span style={{ color: 'red' }}>*</span></label>
                                            <div class="form-control d-flex align-items-center" className={SingupStyle.input}>
                                                <input type={showpssword == true ? 'text' : "password"} class="form-control" id="exampleFormControlInput1" placeholder="Confirm Password " style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent' }} ></input>
                                                <img alt="icon" height={'24px'} src={EyeIcon} style={{ cursor: 'pointer' }} onClick={() => setShowpssword(!showpssword)}></img>
                                            </div>


                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: "10%", gap: "10px" }}>
                                        <input type="checkbox" height={'24px'}></input>
                                        <span className={SingupStyle.accept}>Accept</span>
                                        <span className={SingupStyle.termsConditions}>Terms & Conditions</span>
                                    </div>
                                    <br />
                                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: "10%", gap: "10px", }}>
                                        <button className={SingupStyle.proceedbutton} type="submit">Proceed</button>

                                    </div>
                                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: "10%", gap: "10px", }}>
                                        <span className={SingupStyle.alreadyAMember}>Already a member?</span>
                                        <span className={SingupStyle.login}><Link to='login' style={{ textDecoration: 'none', color: "#233a48" }}>Login</Link></span>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
};

export default SingUp