import React, { useContext, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Badge from '@mui/material/Badge';
import SidebarStyle from '../Sidebar/Sidebar.module.css'
import Lowtechlogo from '../../Assets/Images/SideBarLowTechLogo.png'
import ProfileIcon from '../../Assets/Images/SIdeBarprofileicon.png'
import DashboardIcon from '../../Assets/Images/DashboardIcon.svg'
import ListIcon1 from '../../Assets/Images/ListIcon1.svg'
import ListIcon2 from '../../Assets/Images/ListIcon2.svg'
import ListIcon3 from '../../Assets/Images/access management.svg'
import ListIcon4 from '../../Assets/Images/ListIcon4.svg'
import { UseContext } from '../Context/Context';
import { Link, useNavigate } from 'react-router-dom';
import Toaster from '../Toaster/Toaster';
import { fetchData } from 'pdfjs-dist';
import { CheckSessionLogin, DeleteAllNotification, DeleteNotifcationByID, GetNotifcationbyEmail } from '../BaseApi/Baseapi';
import NotificatinImage from '../../Assets/Images/QcSidebarImage2.svg';
import { set } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

function SideBar({ buttoncolor }) {
    const [open, setOpen] = React.useState(true);
    const [buttunstyle, setbuttunstyle] = useState();
    const { userStatus, adminName, setUserStatus, setAdminName, setIsAuthorized } = useContext(UseContext);
    const [isLogout, setIsLogout] = useState(false);
    const Navigate = useNavigate();
    const [confirmationvisible, setConfirmationVisible] = useState(false);
    const toast = useRef(null);
    const [notification, setNotification] = useState([]);
    const [opendrawer, setOpendrawer] = React.useState(false);
    const [notificationID, setNotificationID] = useState();
    const [getaccess, setGetAccess] = useState([]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    useEffect(() => {
        const Name = sessionStorage.getItem('userName');
        const Role = sessionStorage.getItem('roleName');
        setAdminName(Name);
        setUserStatus(Role);
    }, []);

    const HandelLoagout = () => {
        setIsLogout(true);


        setTimeout(() => {
            sessionStorage.clear();
            setIsAuthorized(false);

            // Replace the current route to prevent navigation back
            window.history.pushState(null, '', '/');
            Navigate('/', { replace: true });
        }, 4300)

    }
    useEffect(() => {
        if (window.location.href == '/login') {

        }
    }, []);

    const accept = () => {
        HandelLoagout();
    }

    const reject = () => {
        setConfirmationVisible(false);
    };

    //     ---------------------------Below code is For API notification--------------------------------

    const GetNotification = async () => {
        try {
            const Email = sessionStorage.getItem('AdminEmail');
            const API = await fetch(`${GetNotifcationbyEmail}${Email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const Responce = await API.json();
            if(Responce.errorCode===404){
                setNotification([]);
            }else{

                
            setNotification(Responce);
            }

        } catch (error) {

        }
    };

    useEffect(() => {
        GetNotification()
    }, []);

    // ----------------------function for formate millisec to Date ---------------------------------------------------
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    // -------------------------Below API is For CLear All Notification One Time -------------------------------------------

    const AllNotificationDelete = async () => {
        
        window.location.reload();
        try {
            const Email=sessionStorage.getItem('AdminEmail');
            const API = await fetch(`${DeleteAllNotification}/${Email}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const Responce = await API.json();
            // callbackFunction();
               
        } catch (error) {
        
    }
};
    //   ----------------------------------Below code is for drawer----------------------------------
    const toggleDrawerNotification = (newOpen) => () => {
        setOpendrawer(newOpen);
        GetNotification();
    };

    const DrawerList = (
        <Box sx={{ width: 450, color: '#424242', fontSize: '12px', fontFamily: 'Poppins' }} role="presentation" onClick={toggleDrawer(false)}>
            <Button
            variant="contained"
            color="success"
            sx={{
                position: 'relative',
                top: 16,
                left: 16,
                fontSize: '10px',
                fontFamily: 'Poppins',
                zIndex: 10,
                 
            }}
            
        >
          Notification
        </Button> 
            <List>
                {notification?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))?.map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>

                            <ListItemText primary={index + 1} />
                        </ListItemButton>
                        <ListItemButton>

                            <ListItemText primary={formatDate(text.createdOn)} />
                        </ListItemButton>
                        <ListItemButton>

                            <ListItemText primary={text.message} />
                        </ListItemButton>
                        <ListItemButton onClick={()=>setNotificationID(text.id)}>

                        <FontAwesomeIcon icon={faX}  />
                        </ListItemButton>
                        
                    </ListItem>
                   
                ))}
            </List>
            <Button
            variant="contained"
            color="error"
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                fontSize: '10px',
                fontFamily: 'Poppins',
                zIndex: 10,
                display:notification.length>0?'block':'none'
            }}
            onClick={()=>AllNotificationDelete()}
            
        >
            Clear All
        </Button> 
        </Box>
    );


    // ----------------------Below code is for delete notification-------------------------------------
    const DeleteNotification = async () => {
        try {
            const API = await fetch(`${DeleteNotifcationByID}${notificationID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const Responce = await API.json();
            GetNotification();
        } catch (error) {
        
    }
};

useEffect(()=>{
    if(notificationID!=null){
        DeleteNotification();
    }
},[notificationID])

//  get Access Number for New Admin  from sessionStorage //
  useEffect(()=>{
       const Access=sessionStorage.getItem('Access');
       setGetAccess(Access);
  },[]);



    return (
        <>
            <Toaster isLogout={isLogout} />
            <div open={open} style={{ height: '100vh', width: '20vw', position: 'relative' }}>
                <Box sx={{
                    width: '21vw', overflowY: 'hidden', height: '100%'
                }}>
                    <div className={SidebarStyle.MainDiv}  >
                        <div className={SidebarStyle.Div1}>
                            <img alt='logo' src={Lowtechlogo} height={'58px'}></img>
                            <Badge badgeContent={notification.length} color="success">
                                <div
                                    style={{
                                        borderRadius: '99px',
                                        backgroundColor: '#f5f5f5',
                                        padding: "8px",
                                        cursor: "pointer",
                                        textAlign: "center",

                                    }} onClick={toggleDrawerNotification(true)} >
                                    <img alt='imagee' src={NotificatinImage} height={'24px'} ></img>


                                </div>
                            </Badge>
                        </div>

                        <div className={SidebarStyle.Div2}>
                            <div>
                                <img alt='logo' height={'36px'} style={{ borderRadius: '50%' }} src={ProfileIcon}></img>
                            </div>
                            <div style={{ marginLeft: '5px' }}>
                                <div className={SidebarStyle.ProfileName}>{adminName}</div>
                                <div className={SidebarStyle.SuperAdmin}>
                                    {userStatus}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "90%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <div onClick={() => setbuttunstyle(1)} style={{display:getaccess.includes(1)?'block':"none"}}>
                                <Link to={getaccess.includes(1) ? '/Dashboard' : '#'}   style={{ textDecoration: "none", color: "black" }} onClick={() => setbuttunstyle(1)}>
                                    <button className={SidebarStyle.Dashboardtext} class="btn  mt-2 w-100 d-flex align-items-center gap-2 " style={{ boxShadow: "none", outline: "none", borderStyle: 'none', color: '#424242', height: "48px", backgroundColor: buttoncolor == 1 ? "#fec83b" : "white" }} >
                                        <img src={DashboardIcon} alt='logo ' height={'24px'} style={{ borderRadius: "4px", color: "black" }}></img>


                                        Dashboard
                                    </button>
                                </Link>

                            </div>

                            <div onClick={() => setbuttunstyle(2)} style={{display:getaccess.includes(2)?'block':"none"}}>
                                <Link to={getaccess.includes(2) ? '/AdminTeam' : '#'}  style={{ textDecoration: "none", color: "black" }} onClick={() => setbuttunstyle(2)}> <button className={SidebarStyle.Dashboardtext} class="btn  mt-2 w-100 d-flex align-items-center gap-2 " style={{ boxShadow: "none", outline: "none", borderStyle: 'none', color: '#424242', height: "48px", backgroundColor: buttoncolor == 2 ? "#fec83b" : "white" }} >
                                    <img src={ListIcon1} alt='logo ' height={'24px'} style={{ borderRadius: "4px", color: "black" }}></img>


                                    Assigning List
                                </button></Link>

                            </div>

                            <div onClick={() => setbuttunstyle(3)} style={{display:getaccess.includes(3)?'block':"none"}}>
                                <Link to={getaccess.includes(3) ? '/ReviewList' : '#'}  style={{ textDecoration: "none", color: "black" }} onClick={() => setbuttunstyle(3)}> <button className={SidebarStyle.Dashboardtext} class="btn  mt-2 w-100 d-flex align-items-center gap-2 " style={{ boxShadow: "none", outline: "none", borderStyle: 'none', color: '#424242', height: "48px", backgroundColor: buttoncolor == 3 ? "#fec83b" : "white" }} >
                                    <img src={ListIcon2} alt='logo ' height={'24px'} style={{ borderRadius: "4px", color: "black" }}></img>


                                    Review List
                                </button></Link>

                            </div>


                            <div onClick={() => setbuttunstyle(4)} style={{display:getaccess.includes(5)?'block':"none"}}>
                                <Link to={getaccess.includes(5) ? '/AccessManagement' : '#'} style={{ textDecoration: "none", color: "black" }} onClick={() => setbuttunstyle(4)}>
                                <button className={SidebarStyle.Dashboardtext} class="btn  mt-2  d-flex align-items-center gap-2 " style={{ boxShadow: "none", outline: "none", borderStyle: 'none', color: '#424242', height: "48px", width: "100%" , backgroundColor: buttoncolor == 4 ? "#fec83b" : "white"}} >
                                    <img src={ListIcon3} alt='logo ' height={'24px'} style={{ borderRadius: "4px", color: "black" }}></img>


                                    Access Management
                                </button>
                                </Link>
                            </div> 
                         
                            <div onClick={() => setbuttunstyle(5)} style={{display:getaccess.includes(4)?'block':"none"}}>
                           
                                <Link to={getaccess.includes(4) ? '/Support' : '#'} style={{ textDecoration: "none", color: "black" }} onClick={() => setbuttunstyle(5)}>
                                    <button className={SidebarStyle.Dashboardtext} class="btn  mt-2 w-100 d-flex align-items-center gap-2 " style={{ boxShadow: "none", outline: "none", borderStyle: 'none', color: '#424242', height: "48px", backgroundColor: buttoncolor == 5 ? "#fec83b" : "white" }} >
                                        <img src={ListIcon4} alt='logo ' height={'24px'} style={{ borderRadius: "4px", color: "black" }}></img>


                                        Support

                                    </button>
                                </Link>

                            </div>
                           
                        </div>

                        {/* <div style={{ position: 'relative' ,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-evenly',width:"90%",backgroundColor:"red"}}>
                            <button  className={SidebarStyle.Div4} class="btn btn-outline-warning mt-2 w-100 d-flex align-items-center gap-2 "  style={{boxShadow:"none",outline:"none",borderStyle:'none',color:'#424242',height:"48px"}}>
                            
                                    <img src={ListIcon1} alt='logo ' height={'24px'} width={'24px'} style={{ borderRadius: "4px",color:"black" }}></img>
                                
                                  <Link to='/AdminTeam' style={{textDecoration:"none",color:"black"}}>Assigning List</Link>  
                                

                            </button>

                            <button className={SidebarStyle.Div4} class="btn btn-outline-warning mt-2 w-100 d-flex align-items-center  gap-2"  style={{boxShadow:"none",outline:"none",borderStyle:'none',color:'#424242',height:"48px"}}>
                                
                                    <img src={ListIcon2} alt='logo ' height={'24px'} width={'24px'} style={{ borderRadius: "4px" }}></img>
                                
                                 <Link to='/ReviewList'style={{textDecoration:"none",color:"black"}}>Review List</Link>   
                                

                            </button>
                            <button className={SidebarStyle.Div4} class="btn btn-outline-warning mt-2 w-100 d-flex align-items-center gap-2 "  style={{boxShadow:"none",outline:"none",borderStyle:'none',color:'#424242',height:"48px"}}>
                                
                                    <img src={ListIcon3} alt='logo ' height={'24px'} width={'24px'} style={{ borderRadius: "4px" }}></img>
                                
                                    Access Management

                            </button>

                            <button className={SidebarStyle.Div4}  class="btn btn-outline-warning mt-2 w-100 d-flex align-items-center  gap-2"  style={{boxShadow:"none",outline:"none",borderStyle:'none',color:'#424242',height:"48px"}}>
                                
                                <img src={ListIcon4} alt='logo ' height={'24px'} width={'24px'} style={{ borderRadius: "4px" }}></img>
                            
                                Support
                            

                        </button>


                        </div> */}

                    </div>

                    <div onClick={() => setConfirmationVisible(true)} icon="pi pi-check" label="Confirm" >
                        <span className={SidebarStyle.Logout} style={{ cursor: 'pointer' }} onClick={() => setConfirmationVisible(true)} icon="pi pi-check" label="Confirm" >Logout</span>
                    </div>

                </Box>

            </div>

            {/* <Box sx={{ width: '100%' ,boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',height:'100vh'}} role="presentation" onClick={toggleDrawer(false)}>
                <List>
                    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? '' : ''}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? '' : ''}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box> */}
            <Toast ref={toast} />
            <ConfirmDialog
                style={{
                    backgroundColor: "rgb(255, 255, 270)",
                    color: 'black', width: '400px',
                    fontFamily: 'Poppins',
                    fontSize: '14px', padding: '20px',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '10%',
                    border: '1px solid black'

                }} visible={confirmationvisible} message="Are you sure you want to Logout?"
                icon="pi pi-exclamation-triangle"
                accept={accept}
                acceptClassName="btn btn-success m-2 "
                reject={reject}
                rejectClassName="btn btn-danger m-2"
            />
            <Drawer anchor="right" open={opendrawer} onClose={toggleDrawerNotification(false)}>
                {DrawerList}
            </Drawer>
        </>
    )
};

export default SideBar;