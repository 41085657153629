const Testserver='65.20.74.205:8080';
const Localhost='localhost:8080';
const ClientServer='db-lawtech.in';
const Server=ClientServer;

// ------------------------------------LOgin--Satart--API------------------------------------//

  export const AdminLogin=`https://${Server}/api/user/login`;

  export const QcLogin=`https://${Server}/api/qc/login`;

  export const InternLogin=`https://${Server}/api/intern/login`;

  export const forgotpassword=`https://${Server}/api/user/sendOtpToMail?`;

  export const verifyopt=`https://${Server}/api/user/verifyOtp?`;

  export const Confirmset=`https://${Server}/api/user/forgotpassword?`;
  export const CheckSessionLogin=`https://${Server}/api/user/checkSessionId/`;


// ------------------------------------LOgin--End--API------------------------------------//






// ---------------------------------Admin- start-API------------------------------------------//
export const GetAllJudegentWokr=`https://${Server}/api/workReport/getAllWorkDataForAdmin`;

export const GetQclist=`https://${Server}/api/judgment/getAllmemberpageData`;

export const GetAllQcWorks=`https://${Server}/api/judgment/getOverAllDataAssigningList`;

export const GeTAllMembers=`https://${Server}/api/judgment/getDataForAssigningList`;

export const GetQcDropdown=`https://${Server}/api/qc/getAllQc`;

export const GetInternListByQcId=`https://${Server}/api/intern/getDataByQCId/`;

export const GetAllValume=`https://${Server}/api/judgment/dropDownDataVolume?`;

export const Getvalumepartnumber=`https://${Server}/api/judgment/dropDownDataPart?`;

export const CreateNewQc=`https://${Server}/api/qc/register`;

export const AdminAllmemebarSearchApi=`https://${Server}/api/judgment/searchMemberPageData?searchValue=`;

export const AdminQcsearch=`https://${Server}/api/judgment/searchForAdmin?searchValue=`;

export const GetTotalCountforAssignJudgment=`https://${Server}/api/judgment/getCountOfAssignedJudgements`;

export const GetTotalCountCAR=`https://${Server}/api/workReport/getTotalCounts`;

export const GetAllTicketListformQcorIntern=`https://${Server}/api/support/tickets/`;

export const ResolveTickets=`https://${Server}/api/support/update/`;

export const GetInternReportByReportId=`https://${Server}/api/workReport/getWorkReport/`;

export const SupportSearchticket=`https://${Server}/api/support/searchAll?searchTerm=`;

export const GetDataonDateRange=`https://${Server}/api/judgment/getFilteredDataForAdminDashBoard`;

export const AdminReviewSearch=`https://${Server}/api/workReport/searchReviewReportsForAdmin?searchTerm=`;

export const StoreApprovedJUdgmentDataWIthCSS=`https://${Server}/api/workReport/saveCssData/`;

// ---------------------------------Admin-End-API------------------------------------------//


// -------------------------------Admin AccessMangnetment- start-API------------------------------------------//

export const CreateNewAdmin=`https://${Server}/api/user/signUp/`;

export const CreateNewRole=`https://${Server}/api/access/save`;

export const GetAllROleForNewAdmin=`https://${Server}/api/access/getAllRoles`;

export const GetAllNewAccessMangmentAdmin=`https://${Server}/api/user/getAllUsersExceptAdmin`;

export const GetAccessForNewAdmin=`https://${Server}/api/access/getAccess/`;

export const DeleteAccessForNewAdmin=`https://${Server}/api/user/updateUser/`;

export const ChangeAccessManagment=`https://${Server}/api/access/changeAccess/`;

// ----------------------------------Qc- start-API------------------------------------------//

export const GetInternLisbyQcId=`https://${Server}/api/intern/allIntern/`;

export const GetValume=`https://${Server}/api/judgment/dropDownDataVolume?`;

export const PartNumber=`https://${Server}/api/judgment/dropDownDataPart?`;

export const GetworkReportIntern=`https://${Server}/api/workReport/getreviewList/`;

export const CreateNewIntern=`https://${Server}/api/intern/registerIntern/`;

export const GetInternListById=`https://${Server}/api/judgment/getDataForAssigningListByqcId/`;

export const GetInternDropdown=`https://${Server}/api/intern/allIntern/`;

export const SearchIntern=`https://${Server}/api/judgment/searchByQcId/`;

export const GetTotalCountInternAssingJudegment=`https://${Server}/api/judgment/getCountOfAssignedJudgementsByQcId/`;

export const GetalloverJudegmentnuber=`https://${Server}/api/judgment/getCountOfAssignedJudgementsByQcId/`;

export const GetALloverCARQc=`https://${Server}/api/workReport/getTotalCounts/`;
export const GetTicketlistPagenationQc=`https://${Server}/api/support/tickets/`;

export const QcReviewListSearch=`https://${Server}/api/workReport/searchReviewListByQcId/`;

export const StoreApprovedJUdgmentDataWIthCSSQC=`https://${Server}/api/workReport/saveCssData/`;

export const QcSupportSearchticket=`https://${Server}/api/support/searchByUser`;

// ----------------------------------Qc-End-API------------------------------------------//


// --------------------------------INTERN- start-API------------------------------------------//

export const PdfStartIndex = `https://${Server}/api/judgment/fileSizeDatabase?`;

export const PdfFileSAssingJudgement = `https://${Server}/api/judgment/fileSizeServer?`;

export const InternCreateJUdgement = `https://${Server}/api/judgment/create/`;

export const QuestionSet = `https://${Server}/api/workReport/updateReport/`;

export const GetJudgement = `https://${Server}/api/judgment/`;

export const GetInterMyWork = `https://${Server}/api/workReport/data/`;

export const GetUpdateworkfromaAdmin=`https://${Server}/api/workReport/getWorkReport/`;

export const GetjudegmentPdf=`https://${Server}/api/judgment/getJudgmentPdf?`;

export const GetjudegmentByInternId=`https://${Server}/api/judgment/`;

export const GetTotalAssingJudegment=`https://${Server}/api/judgment/getCountOfAssignedJudgementsByInternId/`;

export const GetInternCAR=`https://${Server}/api/workReport/totalCountByInternId/`;

export const CreateSuportticekt=`https://${Server}/api/support/raiseRequest`;

export const GetTicketList=`https://${Server}/api/support/tickets/`;

export const GetTickeListPageNation=`https://${Server}/api/support/tickets/`;

export const InternTicketSearh=`https://${Server}/api/support/searchByUser`;

// ------------------------------Intern-End-API------------------------------------------//




// ------------------------------Support Chat API------------------------------------------//
export const CreateSupportChat=`https://${Server}/api/comments/addComment/`;

export const GetSupportChatAdmin=`https://${Server}/api/comments/getComments/`;

export const GetTicketData=`https://${Server}/api/support/getTicketById/`;


// ------------------------------Support Chat API------------------------------------------//



// ------------------------------------Transfer Judgment INTERN TO INTERN--------------------------------------//

export const TransferJudgmentFromQCorIntern=`https://${Server}/api/judgment/dropdown/`;

export const TransferJudgmentToQCorIntern=`https://${Server}/api/judgment/reallocation?`;


// ---------------------------------------------Notification API-------------------------------------//

export const GetNotifcationbyEmail=`https://${Server}/api/notification/`;

export const DeleteNotifcationByID=`https://${Server}/api/notification/deleteNotificationById/`;

export const DeleteAllNotification=`https://${Server}/api/notification/delete`;