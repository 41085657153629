import React, { useEffect, useState } from "react";
import SideBar from "../Sidebar/SIdebar";
import Logo1 from '../../Assets/Images/AccessmangmentEditRole2.png'
import Logo2 from '../../Assets/Images/AccessmanagentEditrole1.png'
import Logo1white from '../../Assets/Images/AccessmangmentEditROlewhite1.png';
import Logo2white from '../../Assets/Images/AccessmanagentEditrolewhite2.png'
import Style from './Accessmangment.module.css'
import { is } from "date-fns/locale";
import NewUserAccessmangment from "./NewUser";
import NewRoleAccessmangment from "./NewRole";
import { ChangeAccessManagment, DeleteAccessForNewAdmin, GetAllNewAccessMangmentAdmin, GetAllROleForNewAdmin } from "../BaseApi/Baseapi";



function AccessmangmentAdmin() {
    const [open, setOpen] = useState(false);
    const [roleopen, setRoleOpen] = useState(false);
    const [buttoncolor, setbuttoncolor] = useState(4);
    const [isEdit, setIsEdit] = useState(() => {
        const saved = sessionStorage.getItem("isEdit");
        return saved ? JSON.parse(saved) : false;
    });
    const [newrole, setNewrole] = useState([]);
    const [adminlist, setAdminlist] = useState([]);
    const [access,setAccess]=useState([]);
    //  below function for open model for to create new user 
    const handleOpen = () => {
        if (isEdit == false) {
            setOpen(true);
        }
    };

    const handleOpenRole = () => {
        if (isEdit == true) {
            setRoleOpen(true);
        }
    };

    const handleClose = () => setOpen(false);
    const handleCloseRole = () => setRoleOpen(false);

    const [selectedRole, setSelectedRole] = useState("Support Manager");
    const [responsibilities, setResponsibilities] = useState({
        Dashboard: false,
        "Assigning List": false,
        "Review List": false,
        Support: false,
    });

    const handleCheckboxChange = (responsibility) => {
        setResponsibilities((prev) => ({
            ...prev,
            [responsibility]: !prev[responsibility],
        }));
    };

    const handleSave = () => {
        console.log("Role:", selectedRole);
        console.log("Responsibilities:", responsibilities);
        alert("Changes saved!");
    };


    //  Below Function For To Get New Role For New Admin  //
    const GetNewRoleForAdmin = async () => {

        try {
            const API = await fetch(`${GetAllROleForNewAdmin}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                },
            });

            const Data = await API.json();
            //   Store API responce in Array State //
            setNewrole(Data);

        } catch (error) {
            setNewrole([]);
            console.error("Error hitting API:", error);
        }
    };


    //   below function for TO Get All AccessMangment Admin List //
    const GetAccessMangmentAdminList = async () => {
        try {
            const API = await fetch(`${GetAllNewAccessMangmentAdmin}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                },
            });

            const Data = await API.json();
            //   Store API responce in Array State //
            setAdminlist(Data);

        } catch (error) {
            setAdminlist([]);

        }
    };

    // hit API when UI update initialy //
    useEffect(() => {
        GetAccessMangmentAdminList();
    }, []);


    // bewlow function for to block access mangment for particuler admin //
    const blockAccessMangment = (id) => {
        try{
        fetch(`${DeleteAccessForNewAdmin}${id}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                status:'BLOCK',
            }),
        }).then(() => {
            GetAccessMangmentAdminList();
        });
    }catch(error){

    }
    };

    // below function for to unblock access mangment for particuler admin //
    const UNblockAccessMangment = (id) => {
        try{
        fetch(`${DeleteAccessForNewAdmin}${id}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                status:'UNBLOCK',
            }),
        }).then(() => {
            GetAccessMangmentAdminList();
        });
    }catch(error){

    }
    };
    
     // below function to Select Access For New Admin//
    const handleClick = (num) => {
        // Check if the value is already in the array
        if (access.includes(num)) {
          // If present, remove it
          setAccess(access.filter((item) => item !== num));
         
        } else {
          // If not present, add it
          setAccess([...access, num]);
        }
      };


    //    Below Function For To Change AccessMangment  //

    const ChangeAccessManagement = async () => {
        try {
            // Ensure 'access' is an array
            const bodyData = JSON.stringify(access);
    
            const response = await fetch(`${ChangeAccessManagment}${selectedRole}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: bodyData, // Send the array as a JSON string
            });
    
            if (!response.ok) {
                // Log errors for debugging
                console.error(`Error: ${response.status} - ${response.statusText}`);
                return;
            }
    
            // Refresh the admin list if successful
            GetAccessMangmentAdminList();
            window.location.reload();
        } catch (error) {
            // Catch and log other errors
            console.error("Request failed:", error);
        }
    };
    
    console.log('access',access)
    return (
        <>
            <div class='d-flex w-100  '>
                <div style={{ position: 'relative', height: '100vh' }}>
                    <SideBar buttoncolor={buttoncolor} />
                </div>
                <div style={{ width: '80%', backgroundColor: '#f5f5f5', height: '100vh', overflow: 'scroll' }}>
                    {/* header Button */}
                    <div class='m-3'>
                        <h6>Access Management</h6>
                        <div style={{
                            width: '442px',
                            height: "68px",
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '12px',
                            boxShadow: '0px 0px 41.73px rgba(0, 0, 0, 0.02)',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            gap: '10px'
                        }}>
                            <div onClick={() => { sessionStorage.setItem('isEdit', false); setIsEdit(false) }} style={{
                                backgroundColor: isEdit == false ? '#FEC83B' : '#f8f8f8',
                                width: "50%",
                                height: "44px", borderRadius: "4px",
                                border: '1px solid #f8f8f8',
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                // padding:'10px 45px',
                                fontSize: '16px',
                                color: '#fff',
                                fontFamily: 'Poppins', cursor: 'pointer',
                            }} >

                                <img src={isEdit == false ? Logo1white : Logo1} style={{ width: '20px', height: '20px' }}></img>
                                <span style={{ color: isEdit == false ? "#fff" : "#424242", fontSize: '12px', fontWeight: '500', fontFamily: 'Poppins', width: "70%" }} > Add New User</span>

                            </div>


                            <div style={{
                                backgroundColor: isEdit == true ? '#FEC83B' : '#f8f8f8',
                                width: "50%",
                                height: "44px", borderRadius: "4px",
                                border: '1px solid #f8f8f8',
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                // padding:'10px 45px',
                                fontSize: '16px',
                                color: '#fff',
                                fontFamily: 'Poppins', cursor: 'pointer',
                            }} onClick={() => { sessionStorage.setItem('isEdit', true); setIsEdit(true); }}>

                                <img src={isEdit == true ? Logo2white : Logo2} style={{ width: '20px', height: '20px' }}></img>
                                <span style={{ color: isEdit == true ? "#fff" : "#424242", fontSize: '12px', fontWeight: '500', fontFamily: 'Poppins', width: "70%" }}>Add/Edit Role</span>

                            </div>

                        </div>
                    </div>
                    {/* search bar and button */}
                    <div class='m-3  d-flex  gap-2 '>
                        <div className={Style.NewAdmin} onClick={handleOpen}>Add New Admin</div>
                        {/* <div className={Style.search}>
                            <input type='text' placeholder='Search' className={Style.searchInput}></input>
                        </div> */}
                    </div>

                    {/* Table */}
                    <div class='m-3' style={{ display: isEdit == false ? 'block' : 'none' }}>
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr className={Style.tableheading}>
                                    <th scope="col">Sr.NO</th>
                                    <th scope="col">Admin email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            {adminlist?.map((item, index) => (<tbody className={Style.tablecolum}>
                                <tr key={index}>
                                    <th >{index + 1}</th>
                                    <td>{item.email}</td>
                                    <td>{item.roleName}</td>
                                    <td class='d-flex justify-content-center align-items-center'> <button className={item.status=='UNBLOCK'?Style.unblockbutton:Style.blockbutton} onClick={item.status=='BLOCK'?()=>UNblockAccessMangment(item.id):()=>blockAccessMangment(item.id)}>{item.status}</button></td>
                                </tr>

                            </tbody>
                            ))}
                        </table>
                    </div>

                    {/* role Table */}
                    <div class='m-3' style={{ display: isEdit == true ? 'block' : 'none' }} >
                        <div className={Style.roleeditorcontainer}>
                            <h2>Edit Role</h2>
                            <div className={Style.formgroup}>
                                <label htmlFor="role" >Select Role:</label>
                                <select
                                    id="role"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                    onClick={GetNewRoleForAdmin}
                                >
                                    <option selected disabled>select Role</option>
                                    {newrole.map((item) => <option >{item}</option>)}

                                </select>
                                <button className={Style.createrolebtn} onClick={handleOpenRole}>Create New Role</button>
                            </div>

                            <div className={Style.responsibilities}>
                                <h4>Assign Responsibilities:</h4>
                               
                                    <div  className={Style.checkboxgroup}>
                                    <input value={1} checked={access.includes(1)} onClick ={()=>handleClick(1)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                    <label for="exampleInputEmail1" className={Style.newrolefrom}>Dashboard</label>
                                    </div>
                                    <div className={Style.checkboxgroup}>
                                            <input value={2} checked={access.includes(2)}  onClick={()=>handleClick(2)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Assigning List</label>
                                        </div>
                                        <div className={Style.checkboxgroup}>
                                            <input value={3} checked={access.includes(3)}  onClick={()=>handleClick(3)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Review List</label>
                                        </div>
                                        <div className={Style.checkboxgroup}>
                                            <input value={4} checked={access.includes(4)}  onClick={()=>handleClick(4)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Support</label>
                                        </div>
                                
                            </div>

                            <button className={Style.savebtn} onClick={ChangeAccessManagement}>
                                Save Changes
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <NewUserAccessmangment props={{ open, handleClose, setOpen }} />
            <NewRoleAccessmangment props={{ roleopen, handleCloseRole, setRoleOpen }} />
        </>
    )
};

export default AccessmangmentAdmin;