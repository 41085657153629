import React, { useContext, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '../../Assets/Images/FormCloseButton.svg'
import EyeIcon from '../../Assets/Images/CloseEyeIcon.svg'
import CopyIcon from '../../Assets/Images/CopyIcon.svg'
import Stack from '@mui/material/Stack';
import { alertClasses } from "@mui/material";
import { UseContext } from "../Context/Context";
import Toaster from "../Toaster/Toaster";
import { CreateNewQc, CreateNewRole } from "../BaseApi/Baseapi";
import Openeyeicon from '../../Assets/Images/googleye.svg'
import Style from './Accessmangment.module.css'

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    // height: '95%',
    boxShadow: 24,
    p: 4,
    outline: 'none',

};

const FormStyle = {

    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    display: 'flex',
    aligItems: 'center',
    justifyContent: 'space-between',
    height: '44px',

};

const FormStyle2 = {
    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    marginTop: '34px',

}

const Buttunstyle = {
    width: '100%',
    position: 'relative',
    borderRadius: '4px',
    bgcolor: '#fec83b',
    border: '1px #fec83b',
    boxSizing: 'border-box',
    height: '44px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 45px',
    fontSize: '16px',
    color: '#fff',
    fontFamily: 'Poppins',
    '&:hover': {

        backgroundColor: '#fec83b',
    },

}



function NewRoleAccessmangment({ props }) {


    const [qcName, setQcName] = useState('');
    const [qcEmail, setQcEmail] = useState('');
    const [qcPassword, setQcPassword] = useState('');
    const [qcConfirmPassword, setQcConfirmPassword] = useState('');
    const [showpssword, setShowpssword] = useState(false);
    const [showconfirmpssword, setShowconfirmpssword] = useState(false);
    const [emailerror, setemailerror] = useState('');
    const [confirm, setConfirm] = useState('');
    const [passworderror, setpasswordError] = useState('');
    const [confirmPasswordcolor, setConfirmPasswordcolor] = useState('');
    const [qccreatesuccessful, setQccreatesuccessful] = useState(false);
    const [error, setError] = useState('');
    const [passwordcolor, setPasswordcolor] = useState('');
    const [copypassword, setCopypassword] = useState('');
    const [role,setRole]=useState('');
    const [confirmrole,setConfirmrole]=useState('');
    const [access,setAccess]=useState([]);
    const [accessserror,setAccessserror]=useState('');
    


    const { qcbkName, setQcbkName,
        qcbkRoll, setQcbkRoll,
        newQc, setNewQc, } = useContext(UseContext)






    const HandelEMail = () => {
        if (qcEmail.length >= 0) {
            if (qcEmail == qcEmail.toUpperCase()) {
                setemailerror('Email should be in lower case')
            } else {
                setemailerror('')
            }
        }
    }

    const Handelpassword = () => {
        const hasUpperCase = /[A-Z]/.test(qcPassword);
        const hasLowerCase = /[a-z]/.test(qcPassword);
        const hasNumber = /\d/.test(qcPassword);

        if (qcPassword.length > 15) {
            setpasswordError('weak password');
            setPasswordcolor('red');
        } else if (!hasUpperCase || !hasLowerCase || !hasNumber) {
            setpasswordError('weak password');
            setPasswordcolor('red');
        } else {
            setpasswordError('Strong Password');
            setPasswordcolor('Green');
            // Clear error if all conditions are met
        }
    }


    const handleCopy = () => {
        navigator.clipboard.writeText(qcConfirmPassword)
            .then(() => {
                // Success feedback
                setCopypassword('Copied');
                ;
            })
            .catch(err => {
                // Error feedback
                setCopypassword('Failed to copy');

            });
    };
    // below function to Select Access For New Admin//
    const handleClick = (num) => {
        // Check if the value is already in the array
        if (access.includes(num)) {
          // If present, remove it
          setAccess(access.filter((item) => item !== num));
        } else {
          // If not present, add it
          setAccess([...access, num]);
        }
      };
      

    
    // below function is FOr to Create New ROle With Specific Access for New Admin //
    const CreatNewAdminRole = async (e) => {
        e.preventDefault();
      
        // Validation flags
        let hasError = false;
      
        // Validate Role Name
        if (role === "" ) {
          setError("Please enter Role name");
          hasError = true; // Mark validation as failed
        }  else {
          setError(""); // Clear previous error
        }
      
        // Validate Access
        if (access.length === 0) {
          setAccessserror("Please select Access");
          hasError = true;
        } else {
          setAccessserror(""); // Clear previous error
        }
      
        // Proceed with API call only if there are no validation errors
        if (!hasError) {
          try {
            const API = await fetch(`${CreateNewRole}`, {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                access: access,
                roleName: role,
              }),
            });
      
            const Data = await API.json();
            console.log(Data);

            // Call Get API After Successfull POST API Call //
             window.location.reload();
            
          } catch (error) {
            console.error("Error hitting API:", error);
          }
        }
      };
      
  

    return (
        <>

            <Modal
                open={props.roleopen}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={FormStyle} >
                        <div>
                            <Typography sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',
                            }}>
                                Create New Role
                            </Typography>

                        </div>
                        <div>
                            <img alt="close" height={'15px'} src={CloseIcon} style={{ cursor: 'pointer' }} onClick={props.handleCloseRole}></img>
                        </div>


                    </Box>

                    <Box sx={FormStyle2}>
                        <div>
                            <form   onSubmit={CreatNewAdminRole}>
                                <div class="form-group">
                                    <label for="exampleInputEmail1" className={Style.newrolefrom}>Enter Role name</label>
                                    <input value={role} onChange={(e)=>setRole(e.target.value)} type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ex: Accountant/Admin,etc"></input>
                                    <p>{error}</p>
                                </div>
                                <br />
                               

                                <div class="form-group">
                                    <br />
                                    <span className={Style.newrolefrom}>Select Menus to give access:</span>
                                    <br />
                                    <div class='d-flex flex-column align-items-start justify-content-start gap-4 '>
                                        <div class='d-flex flex-row align-items-center justify-content-space-between  gap-4'>
                                            <input value={1} checked={access.includes(1)} onClick ={()=>handleClick(1)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Dashboard</label>
                                        </div>
                                        <div class='d-flex flex-row align-items-center justify-content-space-between  gap-4'>
                                            <input value={2} checked={access.includes(2)}  onClick={()=>handleClick(2)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Assigning List</label>
                                        </div>
                                        <div class='d-flex flex-row align-items-center justify-content-space-between  gap-4'>
                                            <input value={3} checked={access.includes(3)}  onClick={()=>handleClick(3)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Review List</label>
                                        </div>
                                        <div class='d-flex flex-row align-items-center justify-content-space-between  gap-4'>
                                            <input value={4} checked={access.includes(4)}  onClick={()=>handleClick(4)} type="checkbox" class="form-check-input" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                                            <label for="exampleInputEmail1" className={Style.newrolefrom}>Support</label>
                                        </div>
                                        <p>{accessserror}</p>

                                    </div>
                                </div>



                                <Stack direction={'row'} sx={{ marginTop: '20px' }}>
                                    <Button sx={Buttunstyle} type="submit">Confirm</Button>
                                </Stack>
                            </form>
                        </div>
                    </Box>
                </Box >
            </Modal >

        </>
    )
};

export default NewRoleAccessmangment