import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import AccessmangmentAdmin from '../AdminAccesmanagment/Accesssmangment';
import AccessManagementRoute from '../AuthLoyOut/AccessMangmentRoute';

// Lazy load components
const SingUp = lazy(() => import('../SingUp/SingUp'));
const LoginPage = lazy(() => import('../Lgin/Login'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const AdminTeam = lazy(() => import('../Adminteam/Adminteam'));
const QcDashboard = lazy(() => import('../QC/QCDashboard/QCDashboard'));
const QcAssingList = lazy(() => import('../QC/QcAssinglist/Qcassinglist'));
const InternDashboard = lazy(() => import('../INTERN/InternDashboard/InternDashboard'));
const PrivateRoute = lazy(() => import('../AuthLoyOut/PrivateRoute'));
const InternWork = lazy(() => import('../INTERN/InternWork/InternWork'));
const InternWorkDashboard = lazy(() => import('../INTERN/WorkDashboard/WorkDashboard'));
const AdminReviewlist = lazy(() => import('../AdminReviewlist/AdminReviewlist'));
const InterListAssingWokr = lazy(() => import('../AdminReviewlist/InternListAssingWork'));
const AdminPrivetRoute = lazy(() => import('../AuthLoyOut/AdminauthoRoute'));
const UpdateInterTask = lazy(() => import('../INTERN/InternWork/InternUpdateWork'));
const QcReviewList = lazy(() => import('../QC/QcreviewList'));
const ForgotPassword = lazy(() => import('../Forgotpassword/Forgotpassword'));
const QcPrivetRoute = lazy(() => import('../AuthLoyOut/Qcauthoroute'));
const InternSupport = lazy(() => import('../INTERN/InternSupport/Internsuppport'));
const AdminSupport = lazy(() => import('../AdminSupport/AdminSupport'));
const QcSupport = lazy(() => import('../QC/QcSupport/QcSupport'));
const QcAssingWokr = lazy(() => import('../QC/QcInternReview/Qcinternreview'));
const AdminSupportChat = lazy(() => import('../AdminSupportchat/AdminSupportchat'));
const InternSupportChat = lazy(() => import('../INTERN/InternSupport/InternSupportchat'));
const QcSupportChat = lazy(() => import('../QC/QcSupport/QcSupportChat'));

function Router() {
    const MainRouter = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><LoginPage /></Suspense>} />
                <Route path="/resetpassword" element={<Suspense fallback={<div>Loading...</div>}><ForgotPassword /></Suspense>} />

                
{/* Admin Privet Route */}
                <Route element={<Suspense fallback={<div>Loading...</div>}><AdminPrivetRoute /></Suspense>}>
                    <Route path="/Dashboard" element={<Suspense fallback={<div>Loading...</div>}><Dashboard /></Suspense>} />
                    <Route path="/AdminTeam" element={<Suspense fallback={<div>Loading...</div>}><AdminTeam /></Suspense>} />
                    <Route path="/ReviewList" element={<Suspense fallback={<div>Loading...</div>}><AdminReviewlist /></Suspense>} />
                    <Route path="/ReviewList/AssingWork" element={<Suspense fallback={<div>Loading...</div>}><InterListAssingWokr /></Suspense>} />
                    <Route path="/Support" element={<Suspense fallback={<div>Loading...</div>}><AdminSupport /></Suspense>} />
                    <Route path="/Chat" element={<Suspense fallback={<div>Loading...</div>}><AdminSupportChat /></Suspense>} />
                    <Route path='/AccessManagement'  element={<Suspense fallback={<div>Loading...</div>}><AccessmangmentAdmin/></Suspense>}></Route>
                </Route>

{/* QC Privet Route */}
                <Route element={<Suspense fallback={<div>Loading...</div>}><QcPrivetRoute /></Suspense>}>
                    <Route path="/Dashboard-Qc" element={<Suspense fallback={<div>Loading...</div>}><QcDashboard /></Suspense>} />
                    <Route path="/Dashboard-Qc/QcAssingList" element={<Suspense fallback={<div>Loading...</div>}><QcAssingList /></Suspense>} />
                    <Route path="/Dashboard-Qc/QcAssingList/ReviewList" element={<Suspense fallback={<div>Loading...</div>}><QcReviewList /></Suspense>} />
                    <Route path="/Qc/ReviewList/InternAssingWork" element={<Suspense fallback={<div>Loading...</div>}><QcAssingWokr /></Suspense>} />
                    <Route path="/Dashboard-Qc/Support" element={<Suspense fallback={<div>Loading...</div>}><QcSupport /></Suspense>} />
                    <Route path="/QC/SupportChat" element={<Suspense fallback={<div>Loading...</div>}><QcSupportChat /></Suspense>} />
                </Route>
{/* Intern Privet Route */}
                <Route element={<Suspense fallback={<div>Loading...</div>}><PrivateRoute /></Suspense>}>
                    <Route path="/InternDashboard" element={<Suspense fallback={<div>Loading...</div>}><InternDashboard /></Suspense>} />
                    <Route path="/InternDashboard/InternWork" element={<Suspense fallback={<div>Loading...</div>}><InternWorkDashboard /></Suspense>} />
                    <Route path="/InternDashboard/InternWork/file" element={<Suspense fallback={<div>Loading...</div>}><InternWork /></Suspense>} />
                    <Route path="/InternDashboard/InternWork/InternUpdateWork" element={<Suspense fallback={<div>Loading...</div>}><UpdateInterTask /></Suspense>} />
                    <Route path="/InternDashboard/Support" element={<Suspense fallback={<div>Loading...</div>}><InternSupport /></Suspense>} />
                    <Route path="/SupportChat" element={<Suspense fallback={<div>Loading...</div>}><InternSupportChat /></Suspense>} />
                </Route>
            </>
        )
    );

    return <RouterProvider router={MainRouter} />;
}

export default Router;
