import React, { useEffect } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toaster({ 
    isSelectRole,
    loginsuccessfully ,
    loginerror,
    emailcaseType,
    emailUperCase,
    roleselect,
    codesetsuccessfuly,
    optverifysuccessfuly,
    resetpasswordsuccessfully,
    qccreatesuccessful,
    interncreatesuccessfully,
    judegmentcreatesuccessfully,
    qcjudegmentcreatesuccessfully,
    judegmentStatus,
    workstatus,
    updateworkshow,
    asnwerstatus,
    isLogout,
    qclogout,
    internlogout,
    errorcode,
    judgmentassingsuccessfully,
}) {

  useEffect(() => {
    if (isSelectRole==false||roleselect==true) {
      toast.info('Please Select Role', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
        transition: Zoom, 
        style: { backgroundColor: '#fec83b', color: 'white' }  
      });
      
    }
    
  }, [isSelectRole,roleselect]);

  useEffect(()=>{
    if(loginsuccessfully==true){
        toast.success('Login Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
          });
            
    }
  },[loginsuccessfully]);

  useEffect(()=>{
    if(codesetsuccessfuly==true){
        toast.success('OPT Send Successfully on Email', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
          });
    }
  },[codesetsuccessfuly])

  useEffect(()=>{
    if(optverifysuccessfuly==true){
        toast.success('OPT Verified Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
          });
    }
  },[optverifysuccessfuly])

  useEffect(()=>{
    if(qccreatesuccessful==true){
        toast.success('QC Created Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
          });
    }
  },[qccreatesuccessful])

  useEffect(()=>{
    if(interncreatesuccessfully==true){
        toast.success('Intern Created Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
          });
    }
  },[interncreatesuccessfully])

  useEffect(()=>{
    if(judegmentcreatesuccessfully||qcjudegmentcreatesuccessfully==true){
        toast.success('Judegment Assigned Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
          });

    }
    
  },[judegmentcreatesuccessfully,qcjudegmentcreatesuccessfully]);

  useEffect(()=>{
    if(codesetsuccessfuly==false){

        toast.info('Invalid User', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });

    }
       
  },[codesetsuccessfuly]);

  useEffect(()=>{
    if(optverifysuccessfuly==false){

        toast.info('OPT Not Verified', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });

    }
       
  },[optverifysuccessfuly]);

  useEffect(()=>{
    if(resetpasswordsuccessfully==true){

        toast.info('Reset Password Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });

    }
       
  },[resetpasswordsuccessfully]);

  useEffect(()=>{
    if(loginerror){

        toast.info('Invalid Email or Password !', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });

    }
       
  },[loginerror]);

   useEffect(()=>{
    if(emailcaseType==true){
        toast.info('Email Should be in lower case', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[emailcaseType])

   useEffect(()=>{
    if(emailUperCase==true){
        toast.info('Email Should be in lower case', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[emailUperCase]);

   useEffect(()=>{
    if(judegmentStatus==false){
        toast.info('Judegement Rejected', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[judegmentStatus]);

   useEffect(()=>{
    if(judegmentStatus==true){
        toast.success('Judegement Approved Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[judegmentStatus]);

   useEffect(()=>{
    if(workstatus==true||updateworkshow==true){
        toast.success('Judegement Submitted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[workstatus,updateworkshow]);

   useEffect(()=>{
    if(asnwerstatus==true){
        toast.info('Please fill all the Answer fields', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[asnwerstatus]);

   useEffect(()=>{
    if(isLogout==true||qclogout==true||internlogout==true){
        toast.success('Logout Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[isLogout,qclogout,internlogout]);

   useEffect(()=>{
    if(errorcode==true){
        toast.info('Selected date cannot be in the future', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[errorcode]);
   useEffect(()=>{
    if(judgmentassingsuccessfully==true){
        toast.success('Judgment transfer Successfully', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            theme: "colored",
            transition: Zoom,
            style: { backgroundColor: '#fec83b', color: 'white' } 
            });
    }
   },[judgmentassingsuccessfully]);
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Zoom}  
    />
  );
}

export default Toaster;
