import React, { useEffect, useState } from 'react';
import Qcstyle from '../QcAssinglist/QcAssing.module.css'
import QcSideBar from '../QcSideBar/QcSideBar';
import Adminstyle from '../../Adminteam/Admin.module.css'
import Profile from '../../../Assets/Images/Profilephoto1.png'

import NewIntern from '../NewIntern/Newintern';
import InternProfile from '../../../Assets/Images/Profilephoto1.png'

import Dropdown from 'react-bootstrap/Dropdown';
import { GetALloverCARQc, GetInternDropdown, GetInternListById, GetTotalCountInternAssingJudegment, GetValume, InternCreateJUdgement, PartNumber, PdfFileSAssingJudgement, PdfStartIndex, SearchIntern } from '../../BaseApi/Baseapi';
import AssingButton from '../../../Assets/Images/AssingInterButtun.svg'
import Toaster from '../../Toaster/Toaster';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function QcAssingList(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [intername, setIntername] = useState([]);
    const [selectintern, setSelectintern] = useState('');
    const [selectyear, setSelectyear] = useState('');
    const [volume, setvolume] = useState([]);
    const [selectvolume, setSelectVolume] = useState('');
    const [partNo, setPartNo] = useState([]);
    const [selectpartnum, setSelectpartnum] = useState('');
    const [pdfstartindex, setPdfstartIndex] = useState('');
    const [pdfEndindex, setPdfEndIndex] = useState([]);
    const [selectendindex, setSelectEndIndex] = useState('');
    const [internID, setInternID] = useState('');
    const [internlist,setInternlist]=useState([]);
    const [qcjudegmentcreatesuccessfully, setQcjudegmentcreatesuccessfully] = useState(false);
    const [buttonstyle, setButtonstyle] = useState(2);
    const [searchintern, setSearchintern] = useState('');
    const [totalcountassingjudegment,setTotalcountassingjudegment]=useState(0);
    const [qcpending,setQcpending]=useState(0);
    const [qcapproved,setQcapproved]=useState(0);
    const [qcRejected,setQcRejected]=useState(0);


    const HnadelInternList = async () => {

        try {
            const QcId = sessionStorage.getItem('QcID')
            const Data = await fetch(`${GetInternListById}${QcId}`, {
                method: "GET",

            })
            const Responce = await Data.json();
            console.log(Responce)
            setIntername(Responce);


        } catch (error) {
            // alert(error)
        }
    };

    const HnadelInternDropdownList = async () => {

        try {
            const QcId = sessionStorage.getItem('QcID')
            const Data = await fetch(`${GetInternDropdown}${QcId}`, {
                method: "GET",

            })
            const Responce = await Data.json();
            console.log(Responce)
            setInternlist(Responce);


        } catch (error) {
            // alert(error)
        }
    };



    useEffect(() => {
        HnadelInternDropdownList();
        HnadelInternList();
    }, [])

    console.log('intername', intername, typeof intername)
    const startYear = 1950;
    const endYear = 2024;
    const years = [];

    for (let i = endYear; i >= startYear; i--) {
        years.push(i);
    };
    years.sort((a, b) => a - b);
    volume.sort((a, b) => a - b);

    const GetAllValumes = async () => {

        try {
            const formData = { year: selectyear }
            const queryParams = new URLSearchParams(formData).toString();
            const Data = await fetch(`${GetValume}${queryParams}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })

            const Responce = await Data.json();
            setvolume(Responce);
            console.log(Responce)

        } catch (error) {
            setvolume([]);
        }
    };

    const GetPartnumber = async () => {
        try {
            const formData = {
                year: selectyear,
                volume: selectvolume,
            };


            const queryParams = new URLSearchParams(formData).toString();

            const response = await fetch(`${PartNumber}${queryParams}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setPartNo(result);
            console.log('partNo', result);
        } catch (error) {
            setPartNo([]);
        }
    };

    useEffect(() => {
        if (selectyear != '') {
            GetAllValumes();
        }
    }, [selectyear]);
    useEffect(() => {
        if (selectvolume != '') {
            GetPartnumber();
        }
    }, [selectvolume]);

    const GetPdgfirstIndex = async () => {
        try {
            const Api1 = `${PdfStartIndex}year=${selectyear}&volume=${selectvolume}&part=${selectpartnum}`;
            const Api2 = `${PdfStartIndex}year=${selectyear}&volume=${selectvolume}`;
            const Data = await fetch(`${selectpartnum == '' ? Api2 : Api1}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json'
                }
            })
            const Responce = await Data.json();
            setPdfstartIndex(Responce)
        } catch (error) {
            // alert(error)
        }
    };

    useEffect(() => {
        if (selectyear != '' || selectvolume != '' || selectpartnum != '') {
            GetPdgfirstIndex();
        }
    }, [selectyear, selectvolume, selectpartnum]);


    const GetPdfFileAllSize = async () => {

        try {
            const Api1 = `${PdfFileSAssingJudgement}year=${selectyear}&volume=${selectvolume}&part=${selectpartnum}`;
            const Api2 = `${PdfFileSAssingJudgement}year=${selectyear}&volume=${selectvolume}`
            const Data = await fetch(`${selectpartnum == '' ? Api2 : Api1}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json'
                }
            });

            if (!Data.ok) {
                throw new Error(`HTTP error! status: ${Data.status}`);
            }

            const Responce = await Data.json();
            // alert(JSON.stringify(Responce));  

            let tempArray = [];
            let startIndex = pdfstartindex === 0 ? 1 : pdfstartindex;
            console.log('tempArray', Responce);
            for (var i = pdfstartindex; i <= Responce; i++) {

                tempArray.push(i);


            }
            
                setPdfEndIndex(tempArray);
                console.log('tempArray', pdfEndindex,);
        





        } catch (error) {
            // alert(`Error: ${error.message}`);
        }
    };


    const CreateJudgement = async () => {

           if(selectintern!=''&&selectyear!=''&&selectvolume!=''&&selectendindex!=''||selectpartnum!=''){

           
        try {


            const formData = new FormData();

            if (selectpartnum == '') {
                formData.append('year', selectyear);
                formData.append('volume', selectvolume);
                formData.append('startLimit', pdfstartindex);
                formData.append('endLimit', selectendindex);
            } else {
                formData.append('year', selectyear);
                formData.append('volume', selectvolume);
                formData.append('part', selectpartnum);
                formData.append('startLimit', pdfstartindex);
                formData.append('endLimit', selectendindex);
            }

            console.log("Sending formData:", formData);
            const qcId = sessionStorage.getItem('QcID');
            const Data = await fetch(`${InternCreateJUdgement}${qcId}/${internID}`, {
                method: "POST",
                body: formData,
            });

            if (!Data.ok) {
                const errorText = await Data.text();
                console.error("Error response from server:", errorText);
                throw new Error(`Error ${Data.status}: ${errorText}`);
            }else{
                setQcjudegmentcreatesuccessfully(true);
                
               setTimeout(() => {
                window.location.reload();
               },4000);
            }

            const Response = await Data.json();

           

        } catch (error) {
            console.error("Request failed:", error);
            // alert(`Request failed: ${error.message}`);
        }
    }
    };


    const qcAssignments = [
        {
            srNo: 1,
            internName: "Ankit Kumar",
            taskAssignedOn: "12/07/24",
            assignedYear: 2010,
            assignedVolume: "Vol 1",
            part: "-",
            noOfJudgement: 55,
            fileReceived: 25,
            qcApproved: 22,
            qcRejected: 3
        },
        {
            srNo: 1,
            internName: "Ankit Kumar",
            taskAssignedOn: "22/08/24",
            assignedYear: 2020,
            assignedVolume: "Vol 2",
            part: "-",
            noOfJudgement: 62,
            fileReceived: 40,
            qcApproved: 35,
            qcRejected: 3
        },
        {
            srNo: 2,
            internName: "John Smith",
            taskAssignedOn: "12/07/24",
            assignedYear: 2011,
            assignedVolume: "Vol 2",
            part: "Part 1",
            noOfJudgement: 56,
            fileReceived: 26,
            qcApproved: 23,
            qcRejected: 3
        },
        // Add remaining objects...
    ];

    const groupedData = intername.reduce((acc, curr) => {
        const intern = curr.internName;
        if (!acc[intern]) acc[intern] = [];
        acc[intern].push(curr);
        return acc;
    }, {});

    console.log('internID',internID);


    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };
   

    const HandelSearch=async()=>{
        try{
            const ID=sessionStorage.getItem('QcID')
            const Data=await fetch(`${SearchIntern}${ID}?searchValue=${searchintern}`,{
                method:"GET",
                headers:{
                    'content-type':'application/json'
                }
            });

            const Responce=await Data.json();
            setIntername(Responce);
        }catch(error){

        }
    };

    useEffect(()=>{
        if(searchintern!=''){
            HandelSearch();
        }
    },[searchintern]);

    const GetTotalAssingCount=async()=>{
        try{
            const ID=sessionStorage.getItem('QcID')
           const Data=await fetch(`${GetTotalCountInternAssingJudegment}${ID}`,{
            method:'GET',
            headers:{
                'content-type':"application/json"
            }

           })
           const Responce=await Data.json();
           setTotalcountassingjudegment(Responce)
           
        }catch(error){
            
        }
    };

    useEffect(()=>{
        GetTotalAssingCount()
    },[])

   

    const GetTotalCAR=async()=>{
        try{
            const ID=sessionStorage.getItem('QcID')
           const Data=await fetch(`${GetALloverCARQc}${ID}`,{
            method:'GET',
            headers:{
                'content-type':"application/json"
            }

           })
           const Responce=await Data.json();
           setQcpending(Responce.completedCount);
           setQcapproved(Responce.approvedCount);
           setQcRejected(Responce.rejectedCount);
           
        }catch(error){
            
        }
    };

    useEffect(()=>{
        
        GetTotalCAR()
    },[])

    return (
        <>
            <div className={Qcstyle.MainDiv}>
                <div className={Qcstyle.SideBar}>
                    <QcSideBar  buttonstyle={buttonstyle}/>
                </div>
                <div className={Qcstyle.List} style={{padding:"10px",position:"relative",width:'80vw'}}>
                    <div className={Adminstyle.Div1}>
                        <div className={Adminstyle.Div1text} class='p-2'>
                            Assign Judgement
                        </div>
                        <div className={Adminstyle.Div2} class='p-2'>
                            <div >

                                <div class="row d-flex justify-content-around align-items-center" >
                                    <div class="col ">
                                        <span className={Adminstyle.Div2text}>Select Intern</span>
                                        <Dropdown>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"

                                            >

                                                <span  >{selectintern == '' ? 'Select Intern' : selectintern}</span>

                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: "fit-content", width: '300px' }}>
                                                {internlist.map((item, index) => (
                                                    <div key={index} style={{ position: 'relative', borderBottom: '2px solid #f5f5f5', }}>
                                                        <Dropdown.Item




                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '10px', }} onClick={() => { setSelectintern(item.internName); setInternID(item.internId) }}>
                                                                <div style={{ width: '50%', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row', left: '-10px', gap: "5px", padding: "10px", paddingTop: "10px" }}>
                                                                    <img
                                                                        src={Profile}
                                                                        alt="profile"
                                                                        style={{ height: '24px', borderRadius: '50%' }}
                                                                    />
                                                                    <span>{item.internName}</span>


                                                                </div>



                                                            </div>





                                                        </Dropdown.Item>



                                                    </div>

                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div class="col">
                                        <span className={Adminstyle.Div2text}> Select Year:</span>
                                        <Dropdown>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"

                                            >

                                                <span>{selectyear == '' ? 'Select year' : selectyear}</span>

                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: "250px", overflowY: 'scroll', width: '100%' }}>
                                                {years.map((year) => (
                                                    <Dropdown.Item key={year} onClick={() => setSelectyear(year)} >
                                                        {year}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div class="col">
                                        <span className={Adminstyle.Div2text} >Select Volume:</span>
                                        <Dropdown>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"

                                            >

                                                <span >{selectvolume == '' ? 'Select Volume' : selectvolume}</span>

                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: volume.length > 3 ? "20vh" : "fit-content", overflowY: 'scroll', width: '100%' }}>
                                                {volume.map((volume) => (
                                                    <Dropdown.Item key={volume} onClick={() => setSelectVolume(volume)} >
                                                        {volume}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div class="col">
                                        <span className={Adminstyle.Div2text}>Select Part No (If appli):</span>
                                        <Dropdown>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"

                                            >

                                                <span >{selectpartnum == '' ? 'Select Part No' : selectpartnum}</span>

                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: volume.length > 3 ? "20vh" : "fit-content", overflowY: 'scroll', width: '100%' }}>
                                                {partNo.map((partNo) => (
                                                    <Dropdown.Item key={partNo} onClick={() => setSelectpartnum(partNo)} >
                                                        {partNo}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div class="col">
                                        <span className={Adminstyle.Div2text}>Select No. of Judgements :</span>
                                        <div style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                            <div className={Adminstyle.Div2Box} style={{ width: "60px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <input readOnly style={{ borderStyle: 'none', outline: 'none', width: "40px", textAlign: "center", barckgroundColor: "transparent", position: 'relative' }} className={Adminstyle.Div2Boxtext} placeholder={pdfstartindex == "" ? 0 : pdfstartindex}></input>

                                            </div>
                                            <Dropdown onToggle={()=>GetPdfFileAllSize()}>

                                                <Dropdown.Toggle
                                                    className={Adminstyle.Div2Box}
                                                    style={{ width: '120px' }}
                                                    variant="white"
                                                    id="dropdown-basic"

                                                >
                                                    <span onClick={()=>GetPdfFileAllSize()}>{selectendindex == '' ? 'Select End' : selectendindex}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ height: pdfEndindex.length > 3 ? "20vh" : "fit-content", overflowY: 'scroll', width: '90px' }}>
                                                    {pdfEndindex.map((item, index) => (
                                                        <Dropdown.Item key={index} onClick={() => setSelectEndIndex(item)} >
                                                            {item}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', width: '40px', marginTop: '20px' }}>
                                        <img src={AssingButton} style={{ height: '40px', cursor: 'pointer',marginRight:"15px" }} onClick={CreateJudgement}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={Adminstyle.TableDiv}>
                        <div class='container ' >
                            <div class="row d-flex " >

                                <div class="col-12 d-flex  flex-row align-items-center justify-content-between gap-2 p-2">
                                    <div class='col-10 ' className={Adminstyle.TableSearchbox}>
                                        <input class='col-9' style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins',
                                            color: '#5c5c5c',
                                            position: 'relative',
                                            borderRadius: '4px',
                                            backgroundColor: '#fff',
                                            // border: '2px solid #dedede',
                                            height: '40px',
                                            overflow: 'hidden',

                                        }} placeholder="Search"  value={searchintern} onChange={(e) => setSearchintern(e.target.value)}></input>
                                        <div class='col-1 d-flex align-items-center justify-content-center' style={{cursor:"pointer"}} onClick={HandelSearch}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                    </div>
                                    <div class='col-2' >
                                        <button className={Adminstyle.TableAddQc} onClick={handleOpen} >Add New Intern</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <table class="table table-bordered ">

                            <thead className={Adminstyle.TableHeader} style={{position:'sticky',top:'0',backgroundColor:'white',}}>
                                <tr   >
                                    <th >Sr.no</th>
                                    <th  >Intern Name</th>


                                    <th >Task Assigned on</th>
                                    <th >Assigned Year</th>
                                    <th>Assigned Volume</th>
                                    <th >Part</th>
                                    <th>No. of Judgement</th>
                                    <th class='text-warning'>Qc Pending</th>

                                    <th style={{ color: 'Secondary-color', backgroundColor: " #fff7d8" }}>QC Approved</th>
                                    <th class='text-danger'>QC Rejected</th>
                                    <th class='text-success'>File Submitted</th>


                                </tr>

                            </thead >

                            <tbody style={{fontSize:"12px",fontFamily:"Poppins",}}>
                                {Object.keys(groupedData).map((internName, internIndex) => {
                                    const works = groupedData[internName];
                                    return works.map((work, workIndex) => (
                                        
                                        <tr key={workIndex}>
                                            {/* Only show the intern name in the first row and use rowSpan */}
                                            {workIndex === 0 && (
                                                <td rowSpan={works.length}>{internIndex + 1}</td>
                                            )}
                                            {workIndex === 0 && (
                                                <td rowSpan={works.length}>{work.internName}</td>
                                            )}
                                            <td>{work.assiginingDate==0?"N/A":formatDate(work.assiginingDate)}</td>
                                            <td>{work.assignedYear}</td>
                                            <td>{work.assignedVolume}</td>
                                            <td>{work.part}</td>
                                            <td>{work.noOfJudgement}</td>
                                            <td>{work.fileReceived}</td>
                                            <td>{work.qcApproved}</td>
                                            <td>{work.qcRejected}</td>
                                            <td>{work.fileReceived+work.qcApproved+work.qcRejected}</td>
                                        </tr>
                                    ));
                                })}
                                <tr  style={{ fontSize: "15px", color: "#424242", fontFamily: "Poppins", borderTop: "2px solid #424242"}}>
                                    <td colSpan={6} class='text-dark'>Total</td>
                                    <td>{totalcountassingjudegment}</td>
                                    <td>{qcpending}</td>
                                    <td>{qcapproved}</td>
                                    <td>{qcRejected}</td>
                                    <td>{qcpending+qcapproved+qcRejected}</td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <NewIntern props={{ open, handleClose, setOpen }}></NewIntern>
            </div >

            <Toaster qcjudegmentcreatesuccessfully={qcjudegmentcreatesuccessfully}/>
        </>
    )
};

export default QcAssingList;