import React, { createContext, useState } from "react";


export const UseContext = createContext();


function MyProvider({ children }) {
    const [singupname, setSingName] = useState('');
    const [singuplastName, setSingupLastName] = useState('');
    const [singupEMail, setSingupEmail] = useState('');
    const [singupPassword, setSingupPassword] = useState('');
    const [singupconfirmPassword, setSingupConfirmPassword] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [adminName, setAdminName] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginpassword, setLoginPassword] = useState('');
   
    const [qcbkName,setQcbkName]=useState('');
    const [qcbkRoll,setQcbkRoll]=useState('');
    const [newQc,setNewQc]=useState({});
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [casePdf,setCasePdf]=useState('');
    const [isopen,setIsopen]=useState(false);
    const [selectpdfId,setSelectpdfId]=useState('');
    const [getPdfLink,setGetPdfLink]=useState('');
    const [getQuestionSet,setGetQuestionSet]=useState([]);
    const[questionset,setQuestionset]=useState([]);
    const [InternreportId,setInternreportId]=useState('');

    
    return (
        <UseContext.Provider
            value={{
                singupname, setSingName,
                singuplastName, setSingupLastName,
                singupEMail, setSingupEmail,
                singupPassword, setSingupPassword,
                singupconfirmPassword, setSingupConfirmPassword,
                userStatus, setUserStatus,
                adminName, setAdminName,
                casePdf,setCasePdf,
                qcbkName,setQcbkName,
                qcbkRoll,setQcbkRoll,
                newQc,setNewQc,
                loginEmail, setLoginEmail,
                loginpassword, setLoginPassword,
                isAuthorized, setIsAuthorized,
                isopen,setIsopen,
                selectpdfId,setSelectpdfId,
                getQuestionSet,setGetQuestionSet,
                getPdfLink,setGetPdfLink,
                questionset,setQuestionset,
                InternreportId,setInternreportId,
            }}
        >
            {children}
        </ UseContext.Provider>
    )

};

export default MyProvider