import React, { useContext, useEffect, useState } from "react";
import { UseContext } from "../Context/Context";
import { Outlet, useNavigate } from "react-router-dom";


function AccessManagementRoute(){
    // const {isAuthorized,setIsAuthorized}=useContext(UseContext);
    const [accessstatus, setAccessStatus] = useState(false);
    const Navigate=useNavigate();
     
      useEffect(()=>{
         const Email=sessionStorage.getItem('AdminEmail');
         const Password=sessionStorage.getItem('AdminconfirmPassword');
         const Access=Number(sessionStorage.getItem('Access'))

       if ([1,2,3,4].includes(Access)) {
        setAccessStatus(true);
      } else {
        setAccessStatus(false);
          Navigate('/', { replace: true });
      }
      },[Navigate,accessstatus]);

   return  accessstatus==true?<Outlet /> :Navigate('/');
};

export default AccessManagementRoute;