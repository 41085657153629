import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import AdminTeam from "./Components/Adminteam/Adminteam.jsx";
import MyProvider from "./Components/Context/Context.jsx";
import Dashboard from "./Components/Dashboard/Dashboard";
import AdminDropdown, { AdminDropdown2 } from "./Components/DropDonws/AdminDropdowns.jsx";
import InternDashboard from "./Components/INTERN/InternDashboard/InternDashboard.jsx";
import InternSideBar from "./Components/INTERN/InternSidebar/InternSidebar.jsx";
import LoginPage from "./Components/Lgin/Login";
import NewUser from "./Components/NewUser/NewUser.jsx";
import QcAssingList from "./Components/QC/QcAssinglist/Qcassinglist.jsx";
import QcDashboard from "./Components/QC/QCDashboard/QCDashboard.jsx";
import Router from "./Components/Router/Router.jsx";
import SideBar from "./Components/Sidebar/SIdebar";
import SingUp from "./Components/SingUp/SingUp";


import {isMobile} from 'react-device-detect';


function App() {
  const [isDesktop, setIsDesktop] = useState(true);

  // Check if the screen width is for desktop or mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to render content based on screen size
  const renderContent = () => {
    if (!isDesktop) {
      return <div> <Modal  show={true}  style={{top:'25%'}}>
      <Modal.Header style={{backgroundColor:"#fec83b",}}>
        <Modal.Title style={{fontFamily:"Poppins",fontSize:"14px",fontWeight:"500"}}> Please select desktop mode. This application only runs in desktop mode</Modal.Title>
      </Modal.Header>
      
      
    </Modal></div>;
    }
    
  };

  return (
    <div className="App">
      <MyProvider>
        {renderContent()}
        {isDesktop && <Router>{/* Add your routes here */}</Router>}
        {/* Uncomment the line below to include the admin dropdown */}
        {/* <AdminDropdown2 /> */}
      </MyProvider>
    </div>
  );
}

export default App;
