import React, { useEffect, useState } from 'react';
import close from '../../Assets/Images/closebutton.svg'
import { Confirmset, forgotpassword, verifyopt } from '../BaseApi/Baseapi';
import { useNavigate } from 'react-router-dom';
import Toaster from '../Toaster/Toaster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [role,setRole]=useState('');
    const [step, setStep] = useState(1);  
    const [emailUperCase, setEmailUperCase] = useState(false);
    const[roleselect,setRoleselect]=useState(false);
    const [codesetsuccessfuly, setCodesetsuccessfuly] = useState();
    const [optverifysuccessfuly,setOptverifysuccessfuly]=useState();
    const [error,seterror]=useState('');
    const Navigate=useNavigate();
    const [confirm,setConfirm]=useState('');
    const [resetpasswordsuccessfully,setResetpasswordsuccessfully]=useState(false);

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email);
        
    };

    const handleCodeSubmit = (e) => {
        e.preventDefault();
        console.log('Code:', code);
        // Move to password setup step
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            console.log('Password successfully reset');
        } else {
            console.log('Passwords do not match');
        }
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f7f7f7',
    };

    const boxStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        width: '500px',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    };

    const titleStyle = {
        color: '#f6a823',
        fontSize: '24px',
        marginBottom: '10px',
    };

    const descriptionStyle = {
        color: '#333',
        fontSize: '16px',
        marginBottom: '20px',
        fontFamily: 'Poppins',
    };


    const inputStyle = {
        width: '100%',
        padding: '10px',
        fontSize: '14px',
        border: '1px solid #e1e1e1',
        borderRadius: '8px',
        marginBottom: '10px',
    };

    const buttonStyle = {
        backgroundColor: '#f6a823',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '8px',
        fontSize: '16px',
        cursor: 'pointer',
        width: '100%',
        marginBottom: '10px',
    };

    const labelStyle = {
        fontSize: '14px',
        textAlign: 'left',
        display: 'block',
        marginBottom: '5px',
    };

    const checkboxStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        justifyContent: 'flex-start',
    };

    const checkboxInputStyle = {
        marginRight: '10px',
    };

    const togglePasswordVisibility = (e) => {
        const target = e.currentTarget.previousSibling;
        target.type = target.type === 'password' ? 'text' : 'password';
    };
    const Goback=()=>{
        window.history.back(-1)
    };

    console.log('Role:', role);
   

    const ResetPassword=async()=>{
       

        if(email!=''&&role==''){
            setRoleselect(true)
            
        }else{
            try{
                const forData=new FormData
                
                forData.append(email,email);
                forData.append(role,role);
                const Data=await fetch(`${forgotpassword}role=${role}&email=${email}`,{
                    method:'POST',
                    headers:{
                        'content-type':'application/json'
                    },
                   
                });
               
                
                
                console.log(Data.ok);
                if(Data.ok==true){
                    setCodesetsuccessfuly(true)
                    setTimeout(()=>{
                        setStep(2)
                    },[200])
                }else {
                    setCodesetsuccessfuly(false);
                }
            }catch(error){
            //    alert(error)
            }
        }
       
    }

    const VerifyOpt=async()=>{
       
        try{
            const forData=new FormData
            
            forData.append(email,email);
            forData.append(role,role);
            const Data=await fetch(`${verifyopt}role=${role}&email=${email}&otp=${code}`,{
                method:'POST',
                headers:{
                    'content-type':'application/json'
                },
               
            });

            if(Data.ok==true){
                setOptverifysuccessfuly(true)
                setTimeout(()=>{
                    setStep(3)
                },[200])
            }else{
                setOptverifysuccessfuly(false);
            }
           
           
        }catch(error){
        //    alert(error)
        }
    }

    
    const ConfirmPassword=async()=>{
        
       if(password!=confirmPassword){
        setConfirm('Confirm Password not match')
       }else{
        setConfirm('')
        try{
            const forData=new FormData
             const Role=sessionStorage.getItem('Reset-Role');
             const Email=sessionStorage.getItem('Reset-Email');
            forData.append(email,Email);
            forData.append(role,Role);
            const Data=await fetch(`${Confirmset}role=${Role}&email=${Email}&password=${password}`,{
                method:'PUT',
                headers:{
                    'content-type':'application/json'
                },
               
            });

            console.log(Data);
            
            if(Data.ok==true){
                setResetpasswordsuccessfully(true)
                // setTimeout(()=>{
                    
                //     window.history.pushState(null, '', '/');
                //     Navigate('/', { replace: true });
                // },[4000]) 
                setTimeout(()=>{
                    window.location.reload();
                },[2000])
                 
            }

           
           
           
        }catch(error){
        //    alert(error)
        }
       }
       
    };

    const HandelEmail=()=>{
        if(email.length>0){
            if(email==email.toUpperCase()){
               
                setEmailUperCase(true)
            }
        }
    }

    useEffect(()=>{
        if(email!=''){
            if(email==email.toUpperCase()){
                setEmailUperCase(true)
                
            }
        }
       
    },[email]);

    const Handelpassword = () => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
    
        if (password.length > 15) {
            seterror('');
        } else if (!hasUpperCase || !hasLowerCase || !hasNumber) {
            seterror('');
        } else {
            seterror('Strong Password'); 
        }
    }
    
    
    return (
        <>
       
        <div style={containerStyle}>
        <div style={{position:'absolute',top:'10px',left:'10px',cursor:'pointer'}} onClick={Goback}>
        <FontAwesomeIcon icon={faArrowLeft} />
        </div>
            <div style={boxStyle}>
                
                
                    <>
                        <h2 style={titleStyle}>Reset Password</h2>
                        <p style={descriptionStyle}>Create a new password</p>
                        <form onSubmit={handlePasswordSubmit}>
                            <label htmlFor="password" style={labelStyle}>Set Password</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="Minimum 8 letters"
                                    value={password}
                                    onChange={(e) => {Handelpassword();setPassword(e.target.value)}}
                                    required
                                    style={inputStyle}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={togglePasswordVisibility}
                                >
                                    👁️
                                </span>
                            </div>
                            <small style={{color:'Green'}}>{error}</small>
                            <small style={{ display: 'block', marginBottom: '10px' }}>
                                Password must contain: Min 8 Characters, Max 15 Characters, 1 Lowercase, 1 Uppercase, 1 Number.
                            </small>
                            <label htmlFor="confirmPassword" style={labelStyle}>Confirm Password</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    placeholder="Re-enter the password"
                                    value={confirmPassword}
                                    onChange={(e) => {setConfirmPassword(e.target.value);}}
                                    required
                                    style={inputStyle}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={togglePasswordVisibility}
                                >
                                    👁️
                                </span>
                            </div>
                            <small style={{color:'Red'}}>{confirm}</small>
                            <div style={checkboxStyle}>
                                <input
                                    type="checkbox"
                                    id="rememberMe"
                                    checked={rememberMe}
                                    onChange={() => setRememberMe(!rememberMe)}
                                    style={checkboxInputStyle}
                                />
                                <label htmlFor="rememberMe">Remember me</label>
                            </div>
                            <button type="submit" style={buttonStyle} onClick={()=>{ConfirmPassword()}} disabled={password!==''||confirmPassword!==''?false:true}>Continue</button>
                        </form>
                    </>
                
            </div>

            <Toaster emailUperCase={emailUperCase} roleselect={roleselect} codesetsuccessfuly={codesetsuccessfuly} optverifysuccessfuly={optverifysuccessfuly} resetpasswordsuccessfully={resetpasswordsuccessfully}></Toaster>
        </div>
        </>
    );
}

export default ForgotPassword;
